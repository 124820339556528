.route-map-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40vh;
    padding : 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 10px;
    gap:15px

}