
.top-modal{
    display: flex;
    justify-content: flex-end 
}
.mid-modal{
    display: flex;
    flex-direction: column;
}
.mid-modal-content{
    height: 500px;
    overflow-y: auto;
    border: 1px solid black;
    border-radius: 0px
}
/* .mid-modal-content::-webkit-scrollbar {
    display:contents;
    width: 7px;
  } */