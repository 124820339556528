.h1,
.h2,
.h3,
.h4 {
  @include margin(0, 0, 15px, 0);
}

.h1 {
  @include margin(0, 0, 0px, 0);
  @include font-size(25px);
  font-family: $font-face-extra-bold;
}

h2,
.h2 {
  @include font-size(20px);
  font-family: $font-face-bold;
  margin-bottom: 16px;
}

.h3 {
  @include font-size(18px);
  font-family: $font-face-bold;
}

.h4 {
  @include font-size(16px);
}

// a {
// @include text-link($brand-color-2, underline, $brand-color-2, none);
// }

a.with-icon {
  @include text-link($font-color, none, $brand-color-2, none);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  @include padding(5px, 10px, 5px, 10px);

  .icon {
    @include margin(null, 5px, null, -5px);
    @include font-size(20px);
    color: $brand-color-2;
  }

  &.is-divider {
    position: relative;
    @include padding(5px, 20px, 5px, 10px);
    margin-right: 10px;

    &:after {
      content: "";
      @include position-absolute(5px, 0px, null, null);
      @include box(1px, 20px);
      background-color: #aaa;
    }
  }

  &.disabled {
    cursor: auto;

    &:hover {
      color: $font-color;
    }
  }
}

p {
  margin-bottom: 25px;
  line-height: 20px;

  &.intro {
    @include font-size(16px);
  }
}

ul.ul {
  @include margin($gutter-small, null, $gutter-medium, null);
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    @include margin(null, null, 5px, null);
    @include padding(null, null, null, 20px);

    &:before {
      @include position-absolute(4px, null, null, 0px);
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{url-friendly-colour($brand-color-2)}' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
    }

    // a {
    // 	color: $font-color;
    // 	text-decoration: none;

    // 	&:hover {
    // 		text-decoration: underline;
    // 		color: $brand-color-2;
    // 	}
    // }
  }
}

ol.ol {
  list-style-type: decimal;
  list-style-position: inside;

  li {
    @include margin(10px, null, 10px, null);
  }
}
