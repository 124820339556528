// ---------------------------------------------------------------------------------------------
// calculate font sizes - convert size to rem and have pixels as fallback
// ---------------------------------------------------------------------------------------------
@mixin font-size($size) {
  font-size: $size;
  font-size: calculate-rem($size);
}

// ---------------------------------------------------------------------------------------------
// get logo styling
// ---------------------------------------------------------------------------------------------
@mixin logo() {
  font-family: 'Qualy';
  font-weight: 800;
}

// ---------------------------------------------------------------------------------------------
// media queries and breakpoints
// ---------------------------------------------------------------------------------------------
@mixin screen-size($breakpoint) {
	@if $breakpoint=='small' {
		@media only screen and (min-width: $breakpoint-small) {
			@content;
		}
	}

	@else if $breakpoint=='medium' {
		@media only screen and (min-width: $breakpoint-medium) {
			@content;
		}
	}

	@else if $breakpoint=='large' {
		@media only screen and (min-width: $breakpoint-large) {
			@content;
		}
	}

	@else if $breakpoint=='xlarge' {
		@media only screen and (min-width: $breakpoint-x-large) {
			@content;
		}
	}

	@else if $breakpoint=='xxlarge' {
		@media only screen and (min-width: $breakpoint-xx-large) {
			@content;
		}
	}

	@else if $breakpoint=='xxxlarge' {
		@media only screen and (min-width: $breakpoint-xxx-large) {
			@content;
		}
	}
}

// ---------------------------------------------------------------------------------------------
// padding
// ---------------------------------------------------------------------------------------------
@mixin padding($padding-top, $padding-right, $padding-bottom, $padding-left) {
  padding-top: $padding-top;
  padding-right: $padding-right;
  padding-bottom: $padding-bottom;
  padding-left: $padding-left;
}

// ---------------------------------------------------------------------------------------------
// margin
// ---------------------------------------------------------------------------------------------
@mixin margin($margin-top, $margin-right, $margin-bottom, $margin-left) {
  margin-top: $margin-top;
  margin-right: $margin-right;
  margin-bottom: $margin-bottom;
  margin-left: $margin-left;
}

// ---------------------------------------------------------------------------------------------
// position absolute
// ---------------------------------------------------------------------------------------------
@mixin position-absolute($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// ---------------------------------------------------------------------------------------------
// box or rectangle
// help: input height and width or just width which makes height the same
// ---------------------------------------------------------------------------------------------
@mixin box($box-width, $box-height:$box-width) {
  width: $box-width;
  height: $box-height;
}

// ---------------------------------------------------------------------------------------------
// set border of element
// help: leave border-sides blank for all side the same
// ---------------------------------------------------------------------------------------------
@mixin border($border-width, $border-style, $border-color, $border-sides:'') {
  @if ($border-style !='') {
    @if ($border-sides=='') {
      border: $border-width $border-style $border-color;
    }

    @else {
      @each $border-side in $border-sides {
        @if ($border-side=='top'or $border-side=='right'or $border-side=='bottom'or $border-side=='left') {
          border-#{$border-side}: $border-width $border-style $border-color;
        }
      }
    }
  }
}

// ---------------------------------------------------------------------------------------------
// box shadow
// ---------------------------------------------------------------------------------------------
@mixin box-shadow {
  box-shadow: #d1d4d3 0 0 .5em 0;
}

// ---------------------------------------------------------------------------------------------
// border radius
// ---------------------------------------------------------------------------------------------
@mixin border-radius($top-left, $top-right, $bottom-right, $bottom-left) {
  border-top-left-radius: $top-left;
  border-top-right-radius: $top-right;
  border-bottom-right-radius: $bottom-right;
  border-bottom-left-radius: $bottom-left;
  background-clip: padding-box;
}

// ---------------------------------------------------------------------------------------------
// a link
// ---------------------------------------------------------------------------------------------
@mixin text-link($color, $text-decoration, $hover-color, $hover-text-decoration) {
  color: $color;
  text-decoration: $text-decoration;

  &:hover {
    color: $hover-color;
    text-decoration: $hover-text-decoration;
  }

  &:visited {
    color: $color;
  }
}

// ---------------------------------------------------------------------------------------------
// horizontal rule
// ---------------------------------------------------------------------------------------------
@mixin horizontal-rule {
  height: 0;
  border: none;
  border-top: 1px solid $grey-box-border;
}

// ---------------------------------------------------------------------------------------------
// chevrons
// ---------------------------------------------------------------------------------------------
@mixin arrow-right($color, $hover-color, $border-width) {
  border: solid $color;
  border-width: 0 $border-width $border-width 0;
  display: inline-block;
  padding: 4px;

  &:hover {
    border-color: $hover-color;
  }

  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

@mixin arrow-left($color, $hover-color, $border-width) {
  border: solid $color;
  border-width: 0 $border-width $border-width 0;
  display: inline-block;
  padding: 4px;

  &:hover {
    border-color: $hover-color;
  }

  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

@mixin arrow-up($color, $hover-color, $border-width) {
  border: solid $color;
  border-width: 0 $border-width $border-width 0;
  display: inline-block;
  padding: 4px;

  &:hover {
    border-color: $hover-color;
  }

  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

@mixin arrow-down($color, $hover-color, $border-width) {
  border: solid $color;
  border-width: 0 $border-width $border-width 0;
  display: inline-block;
  padding: 4px;

  &:hover {
    border-color: $hover-color;
  }

  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

// ---------------------------------------------------------------------------------------------
// truncate text
// ---------------------------------------------------------------------------------------------
@mixin truncate($width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $width;
}


// ---------------------------------------------------------------------------------------------
// alert styling
// ---------------------------------------------------------------------------------------------
@mixin success-style() {
	@include border(4px, solid, $color-success, left);
	background-color:$color-success-background;
	color: $color-success;
}

@mixin error-style() {
    @include border(4px, solid, $color-error, left);
	background-color: $color-error-background;
	color: $color-error;
}

@mixin warning-style() {
	@include border(4px, solid, $color-warning, left);
	background-color: $color-warning-background;
	color: $color-warning;
}

@mixin info-style() {
	@include border(4px, solid, $color-info, left);
	background-color: $color-info-background;
	color: $color-info;
}

// ---------------------------------------------------------------------------------------------
// account status styling
// ---------------------------------------------------------------------------------------------

@mixin account-status-grey() {
	color: #383f43;
	background: #ccc;
}

@mixin account-status-purple() {
	color: #3d2375;
	background: #dbd5e9;
}

@mixin account-status-turquoiuse() {
	color: #10403c;
	background: #bfe3e0;
}

@mixin account-status-blue() {
	color: #144e81;
	background: #d2e2f1;
}

@mixin account-status-light-blue {
	color: #0d4a60;
	background-color: #a7eff2;
}

@mixin account-status-yellow() {
	color: #594d00;
	background: #fff7bf;
}

@mixin account-status-orange() {
	color: #6e3619;
	background: #fcd6c3;
}

@mixin account-status-red() {
	color: #942514;
	background: #f6d7d2;
}

@mixin account-status-pink() {
	color: #80224d;
	background: #f7d7e6;
}

@mixin account-status-green() {
	color: #005a30;
	background: #cce2d8;
}

@mixin account-status-border() {
	background: #fff;
	@include border(1px, solid, #999);
}