.notification-wrapper {
    position:fixed;
    top : 80px;
    right:40px;
    z-index: 100;
    width: 300px;
    /* background-color: aqua; */
}
.notification-item {
    /* padding: 5px; */
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    animation : SlideLeft 1.2s;
    animation-fill-mode: forwards;
    width: 300px;
}
@keyframes SlideLeft {
    0%{
        margin-left: 130%;
    }
    100%{
        margin-left: 0%;
    }
}
@keyframes SlideRight {
    0%{
        margin-left: 0%;
    }
    100%{
        margin-left: 130%;
    }
}

.notification-item.exit{
    animation: SlideRight 1.2s;
    animation-fill-mode: forwards;
}
.notification-item p {
    margin : 0;
    padding: 10px;
    margin-left: 10px ;
}
.notification-item .bar {
    margin: 0;
    height : 10px;
    width: 100%;
    background-color: tomato;
}
.notification-item.success .bar {
    background-color: rgb(14, 226, 14);
}
.notification-item.error .bar {
    background-color: rgb(248, 6, 6);
}