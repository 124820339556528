.button, .button-text {
	@include box(null, 35px);
	@include padding(null, 15px, null, 15px);
	border: none;
	background: white;
	width: auto;
	cursor: pointer;
	border-radius: 0;

	&:disabled {
		background-color: #ccc !important;
		border-color: transparent !important;
		color: #666 !important;

		&:hover {
			background-color: #ccc !important;
		}
	}

	&.with-icon {
		display: inline-flex;
		align-items: center;
		cursor: pointer;
        white-space: nowrap;

		.icon {
			@include margin(null, 8px, null, null);
		}
	}

	&.with-icon-only {
		width: 35px;
		@include padding(0px, 0px, 0px, 0px);

		.icon {
			@include margin(null, 0, null, null);
		}
	}

	&.primary {
		background-color: $button-primary-background;
		color: $button-primary-color;

		&:hover {
			background-color: lighten($button-primary-background, 10%);
		}
	}

	&.secondary {
		background-color: $button-secondary-background;
		color: $button-secondary-color;

		&:hover {
			background-color: lighten($button-secondary-background, 5%);
		}
	}

	&.tertiary {
		background-color: $button-tertiary-background;
		color: $button-tertiary-color;
		@include border(1px, solid, $button-secondary-background);

		&:hover {
			border-color: $button-primary-background;
			color: $button-primary-background;
		}
	}

	&.danger {
		background-color: $color-error-background;
		color: $color-error;
		@include border(1px, solid, $color-error);

		&:hover {
			background-color: darken($color-error-background, 10%);
		}

		&.outlined {
			background-color: transparent;
			border-color: $color-error-background;
			color: $color-error-background;

			&:hover {
				background-color: $color-error-background;
				color: #fff;
			}
		}
	}
}

.icon-and-text {
	display: inline-flex;
	align-items: center;
	color: #495057;

	.icon {
		@include margin(0px, 5px, null, null);
		@include font-size(25px);
		font-variation-settings:  'FILL' 0,   'wght' 300,   'GRAD' -25,   'opsz' 40;
		color: $brand-color-2;

		&.no-margin {
			margin: 0;
		}

	}

	&:hover {
		color: $brand-color-2;
		cursor: pointer;
	}

	&.reverse {
		color: $brand-color-2;

		&:hover {
			color: $font-color;
		}
	}

	&.disabled {
		opacity: 0.5;
	}

	&.has-left-border {
		@include margin(null, null, null, 10px);
		@include padding(null, null, null, 10px);
		@include border(1px, solid, $grey-box-border, left);
	}
}
