.alter_location_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
     align-items: center;
     width: 100%;
     /* gap: 20px */
}
.alter_location_content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:80px

}
.alter_location_content h2 {
    font-size: 20px;
    font-weight: 500
}
.location_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    justify-content: center;
    padding: 30px;
}