@import 'scss/abstracts/variables';
@import 'scss/abstracts/functions';
@import 'scss/abstracts/mixins';

.footer {
	display: flex;
	align-items: center;
	margin-top: auto;
	@include padding(5px, 25px, 5px, 25px);
	background-color: #ccc;
    @include border(1px, solid, $grey-box-border, top);
	color: #333; 

	.logo {
		margin-left: auto;
        @include logo();
		@include font-size(20px);
	}

	.copyright {
		margin-left: 0;
		@include font-size(12px);
		opacity: 0.75;
	}
}