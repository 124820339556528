@import 'scss/abstracts/variables';
@import 'scss/abstracts/functions';
@import 'scss/abstracts/mixins';

.page-menu {
	min-width: 230px;
	@include border(1px, solid, $grey-box-border, right);
	background-color: #fff;

	.nav {
        @include margin(10px, null, 20px, null);

        ul {
            width: 100%;
            padding:0
        }

        .item {
            display: flex;
            align-items: center;
            @include padding(10px, 10px, 10px, 15px);
            text-decoration: none;
            cursor: pointer;

            .icon {
                @include margin(null, 10px, null, null);
                font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 200, 'opsz' 20;
                color: #666;
            }


            &:hover {
                background-color: $grey-box-background;
                // color: #fff;

                // .icon {
                //     color: #fff;
                // }

            }
        }
        .item.active {
            background-color: $grey-box-active-background;
        }

	}

}