// @tailwind base;
// @tailwind components;
// @tailwind utilities;


@font-face {
	font-family: "Qualy";
	src: local("Qualy"),
	 url('./static/fonts/Qualy/Qualy.ttf') format("truetype");
	font-weight: bold;
	}
body{
	background: #f8f8fa;
}
// a {
// 	color: #3546A2
// }
h1{
	font-size: 32px;
}

/* .site-container {
	width: 200px;
	height: 100%;
} */

/* .site-container>*{
	max-width: 1600px;
} */

@media screen and (min-width: 1600px){
	.site-container{
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
}