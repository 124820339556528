﻿.inline-block {
	display: inline-block;
}

.inline { 
	display: inline;
}

.position-relative {
	position: relative;
}

.flex-row {
	display: flex;

	.columns {
		flex-direction: column;
	}

	.align-center {
		align-items: center;
	}
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.text-right {
	text-align: right !important;
}

.text-left {
	text-align: left !important;
}

.nowrap {
	white-space: nowrap;
}

.vertical-align-top {
	vertical-align: top !important;
}

.font-weight-bold {
	font-weight: 500;
}