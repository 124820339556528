.business-drop-list-box {
    padding: 8px;
    border-radius: 0px;
  }
  
  .business-drop-list-title {
    display: flex;
    align-items: left;
    padding: 0px;
    cursor: pointer;
  }
  
  .business-drop-list-element span {
    /* color: black; */
    color: #0000FF;
    text-decoration: underline;
    margin-left: 24px;
    margin-top: 0px;
    cursor: pointer;

  }

  .business-drop-list-element:hover span{
    color: grey;
  }