@import "scss/abstracts/variables";
@import "scss/abstracts/functions";
@import "scss/abstracts/mixins";
@import "scss/base/helpers";

.notification-count {
  position: absolute;
  display: flex;
  color: white;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -10px;
  top: -11px;
  background: red;
  border-radius: 50%;
}

.header {
  display: flex;
  height: 70px !important; // remove after styling completed
  align-items: center;
  @include padding(15px, 20px, 15px, 20px);
  background: $brand-color-1;
  border-bottom: 1px solid #ccc;

  .toggle-sidebar {
    display: flex;
    align-items: center;

    .icon {
      @include font-size(30px);
      color: #fff;
      cursor: pointer;
      @include margin(5px, 15px, null, null);

      // &.active {
      // 	color: $brand-color-1;
      // }
    }
  }

  .logo {
    @include logo();
    @include margin(15px, 15px, null, null);
    font-size: 36px;
    color: #fff;
  }

  .current-fleet {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 54px;
    @include padding(0px, null, null, 14px);

    // &:before {
    // 	content: '';
    // 	@include position-absolute(2px, null, null, 0px);
    // 	@include box(1px, 35px);
    // 	background-color: #ccc;
    // }

    .title {
        color: #999;
      }
  
    .fleet-name {
        @include margin(0px, null, null, null);
        color: #fff;
        text-transform: capitalize;
    }

    .change-fleet {
      position: relative;
      @include margin(null, null, null, 10px);
      @include padding(null, null, null, 10px);

      &:before {
        content: "";
        @include position-absolute(null, null, null, 0px);
        @include box(1px, 20px);
        background-color: #ccc;
      }
    }
  }

  .nav {
    display: flex;
    @include margin(5px, null, null, auto);

    .item {
      position: relative;
      @include margin(null, null, null, 10px);
      cursor: pointer;

      @include screen-size(medium) {
        @include margin(null, null, null, 25px);
      }

      .icon {
        @include font-size(25px);
        font-variation-settings: "FILL" 0, "wght" 300, "GRAD" -25, "opsz" 40;
        color: #999;
      }

      .title {
        display: none;
        @include margin(null, null, null, 5px);
        color: #fff;
        font-weight: 700;

        @include screen-size(medium) {
          display: inline-block;
        }
      }

      a {
        display: flex;
        align-items: center;
      }
    }
  }

  .dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1000;
    width: 350px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    cursor: auto;

    .profile {
      display: flex;
      flex-direction: column;

      .info {
        display: flex;
        @include margin(20px, 20px, 5px, 20px);

        .name-and-email {
          display: flex;
          flex-direction: column;
          @include font-size($font-size-medium);
        }
      }

      .avatar {
        @include box(60px);
        @include border-radius(100px, 100px, 100px, 100px);
        @include margin(null, 20px, null, null);
      }

      .actions {
        display: flex;
        @include margin(20px, null, null, null);
        @include padding(10px, 20px, 10px, 20px);
        @include border(1px, solid, $grey-box-border, top);
        background-color: $grey-box-background;
      }
    }

    .notifications {
      display: flex;
      flex-direction: column;
      max-height: 70vh;

      .title {
        @include margin(null, null, 25px, null);
        @include padding(10px, 20px, 10px, 20px);
        @include border(1px, solid, $grey-box-border, bottom);
        @include font-size($font-size-base);
      }

      .list {
        @include padding(10px, 20px, 10px, 20px);
      }
    }

    .button {
      @include font-size($font-size-medium);
      @include border(1px, solid, $grey-box-border);
      @include padding(5px, 10px, 5px, 10px);
      background: #fff;

      &:hover {
        background-color: #ededed;
      }

      &.sign-out {
        margin-left: auto;
      }
    }
  }
}
