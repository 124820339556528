.user-survey-container{
    max-width: 1600px;
    flex-direction: column;
    width : 100%;
    display: flex;
    padding: 32px;
    

    
}
.user-survey-title{
    font-weight: bolder;
    font-size: 30px;
    text-align: center;
}

.radio-options div {
    margin-bottom: 16px; /* Adjust spacing between options */
}

.radio-options label {
    display: block; /* Ensure the label uses the full width of its container */
    cursor: pointer; /* Change mouse cursor to indicate clickable area */
}

.radio-options input[type="radio"] {
    margin-right: 16px; /* Space out the radio button from its label */
}

