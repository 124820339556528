@import 'scss/abstracts/variables';
@import 'scss/abstracts/functions';
@import 'scss/abstracts/mixins';

.help-panel {
	position: relative;
	@include padding(15px, 15px, 15px, 15px);
	@include border(1px, solid, $grey-box-border, left);
	background-color: #fff;
	width: 385px;

	&-close {
		@include position-absolute(15px, 15px, null, null);
		font-variation-settings:  'FILL' 0,   'wght' 200,   'GRAD' -25,   'opsz' 40;
		color: $brand-color-1;
	}


	&-title {
		position: relative;
		display: flex;
		flex-direction: column;

		.name {
			@include logo();
			@include font-size(20px);
			color: $brand-color-2;
		}

		.tag-line {
			@include margin(-8px, null, null, null);
			@include font-size(14px);
			color: #666;
			text-transform: capitalize;
		}
	}

	&-content {
		@include margin(15px, null, null, null);
		height: calc(100% - 75px);
		overflow-y: auto;
	}

}