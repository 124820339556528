


.table{
   border-collapse:collapse;
   border-spacing:0;
   border-color:#ccc;
}
.table tr {
    justify-content: center;
    align-items: center
}
.table td{
  font-family:Arial, sans-serif;
  font-size:14px;padding:10px 5px;
  border-style:solid;
  border-width:0px;
  overflow:hidden;
  word-break:normal;
  border-color:#ccc;
  color:#333;
  background-color:transparent;
}
.table th{
  font-family:Arial, sans-serif;
  font-size:14px;
  font-weight:normal;
  padding:10px 5px;
  border-style:solid;
  border-width:0px;
  overflow:hidden;
  word-break:normal;
  border-color:#ccc;
  color:#333;
  background-color:#f0f0f0;
}

.table .line{
    float: left;
    width: 80%;
    height: 16px;
    margin-top: 12px;
    border-radius: 7px;

}

/*  
@keyframes shine-lines{
    0% { background-position: -100px;}
    40%, 100% {background-position: 140px;}
} */
