@import "scss/abstracts/variables";
@import "scss/abstracts/functions";
@import "scss/abstracts/mixins";

.dashboard-card {
  display: flex;
  flex-direction: column;
  @include margin(null, null, 20px, null);
  @include border(1px, solid, $grey-box-border);
  background-color: #fff;
  height: auto;
  min-height: 200px;

  &-title {
    display: flex;
    align-items: center;
    @include padding(15px, 20px, 15px, 20px);
    @include font-size(16px);
    background-color: rgba(87, 177, 151, 0.20);
    @include border(1px, solid, $grey-box-border, bottom);

    .icon {
      @include font-size(25px);
      @include margin(null, 5px, null, null);
      font-variation-settings: "FILL" 0, "wght" 300, "GRAD" -25, "opsz" 40;
      color: $brand-color-2;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    @include padding(20px, 15px, 15px, 20px);
  }

  &-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include margin(auto, 15px, 15px, 15px);
    @include padding(15px, null, null, null);
    @include border(1px, solid, $grey-box-border, top);
  }
}

// Responsive Adjustments
@media (max-width: 1024px) {
  .dashboard-card {
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .dashboard-card {
    width: 100%;
    height: auto;
  }

  .dashboard-card-title {
    flex-direction: column;
    text-align: center;
  }

  .dashboard-card-content {
    padding: 15px;
  }

  .dashboard-card-actions {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
}

.box-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @include padding(null, null, 5px, null);

  .item {
    flex: 1;
    min-width: 120px;
    max-width: 100%;
    font-weight: 500;
  }

  .data {
    color: #000;
    font-weight: 500;
  }
}

// Small screens
@media (max-width: 600px) {
  .box-info {
    flex-direction: column;
    gap: 10px;
  }

  .item {
    width: 100%;
    text-align: center;
  }
}
