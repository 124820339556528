
  
  /* ------------------------- Separate line ------------------------- */
  
  :root {
    --breadcrumb-theme-1: #000000;  /* color | background active */
    --breadcrumb-theme-2: #ffffff;  /* background | color active */
    --breadcrumb-theme-3: #E91E63;  /* hover background (no longer used) */
    --breadcrumb-theme-4: #111;     /* arrow color */
  }
  
  .breadcrumb {
    text-align: center;
    display: flex;
    justify-content: space-between; /* Spread items evenly across the width */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border-radius: 5px;
    width: 100%; /* Ensure the breadcrumb takes the full width */
    counter-reset: flag;
  }
  
  .breadcrumb__step {
    text-decoration: none;
    outline: none;
    display: block;
    flex-grow: 1; /* Allow each step to take equal space */
    font-size: 16px;
    line-height: 48px;
    padding: 0 10px 0 60px;
    position: relative;
    background: var(--breadcrumb-theme-2);
    color: var(--breadcrumb-theme-1);
    transition: none; /* Disable transitions */
    pointer-events: none; /* Disable click events */
  }
  
  .breadcrumb__step:first-child {
    padding-left: 46px;
    border-radius: 5px 0 0 5px;
  }
  
  .breadcrumb__step:last-child {
    border-radius: 0 5px 5px 0;
    padding-right: 20px;
  }
  
  /* .breadcrumb__step::before {
    content: counter(flag);
    counter-increment: flag;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 8px 0;
    position: absolute;
    top: 0;
    left: 30px;
    font-weight: bold;
    background: var(--breadcrumb-theme-2);
    box-shadow: 0 0 0 1px var(--breadcrumb-theme-1);
  }
   */
  .breadcrumb__step::after {
    content: '';
    position: absolute;
    top: 0;
    right: -24px;
    width: 48px;
    height: 48px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    border-radius: 0 5px 0 50px;
    background: var(--breadcrumb-theme-2);
    box-shadow: 2px -2px 0 2px var(--breadcrumb-theme-4);
  }
  
  .breadcrumb__step--active {
    color: var(--breadcrumb-theme-2);
    background: var(--breadcrumb-theme-1);
  }
  
  .breadcrumb__step--active::before {
    color: var(--breadcrumb-theme-1);
  }
  
  .breadcrumb__step--active::after {
    background: var(--breadcrumb-theme-1);
  }
  