.marker {
    cursor: pointer;
    transition: transform .2s; /* Animation */
    // background-color: #dbfae2;
    /* width: 13px; */
    /* height: 13px; */
    top: 0px;
    // outline: thick solid rgba(1, 141, 17, 0.3);
    border-radius: 50%;
    outline-width: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.marker.AGREED {

    background-color: #dbfae2;
    outline: thick solid rgba(1, 141, 17, 0.3);

}

.marker.POTENTIAL {

    background-color: #f0f0f0;
    outline: thick solid rgba(1, 141, 17, 0.3);

}

.marker.REQUESTED {

    background-color: #ffcc00;
    outline: thick solid rgba(1, 141, 17, 0.3);

}

.marker.OFFERED {

    background-color: #ffcc00;
    outline: thick solid rgba(1, 141, 17, 0.3);

}

.marker:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

}
