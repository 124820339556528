@import 'scss/abstracts/variables';
@import 'scss/abstracts/functions';
@import 'scss/abstracts/mixins';

.sidebar {
    @include position-absolute(70px, null, 0px, 0px);
    width: 310px;
    z-index: 250;
    // height:100vh;
    padding-right: 10px; 
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    @include border(1px, solid, #ccc, right);
    background-color: #fff;
    transition:all 0.5s ease-in-out;
    // height: 100%;

    &.close {
        left: -310px;


    }

    .nav {
        @include padding(20px, null, 20px, null);

        ul {
            width: 100%;
            padding:0
        }

        .item {
            display: flex;
            align-items: center;
            @include padding(10px, 10px, 10px, 15px);
            text-decoration: none;
            cursor: pointer;

            .icon {
                @include margin(null, 10px, null, null);
                font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 200, 'opsz' 20;
                color: #666;
            }

            // &:hover {
            //     background-color: $grey-box-background;
            // }
        }
    }
}

@keyframes slide {
    100% { left: 0; }
}
.item { 
    display: flex;
    justify-content: space-between;

}
.item.hover {
    background-color:$grey-box-background ;
}
.item.active {
    background-color:$grey-box-active-background ;
}
.main-child-container {
    position: absolute;
    left: 280px;
    max-width: 204px;
    width: 100%;
    top: auto;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    background-color: white;
    
}
.link_container:hover{
    background-color:$grey-box-background  ;
}