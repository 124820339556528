.login-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 0px;
  width: 100vw;
  height: 100vh;
  font-size: 15px;
  /* padding: 200px; */
  font-family: Poppins;
  /* background-color: red; */
  padding: 0px;
  overflow-y: scroll;
  background-color: #fff;
}
.login-container input {
  padding: 10px;
  border: 1px solid grey;
  border-radius: 1px;
  /* border-radius: 5px; */
}
input.checkbox {
  width: 20px;
  height: 20px;
}

.image-login-container {
  position: relative;
  flex: 2;
  /* background-image: url("../../static/Preview_images/van-login.png"); */
  background-position: center;
  background-size: 950px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 20px 0px 0px 20px;
}
.form-login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;

  /* background-color: aqua; */
}
.login-logo {
  font-family: "Qualy";
  font-weight: "bolder";
  font-size: 40px;
  cursor: pointer;
}
.form-login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.form-login-inner-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.form-login-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.checkbox-group {
  display: flex;
  align-items: center;
  gap: 20px;
}
.login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: black;
  width: 200px;
  color: white;
  border-radius: 10px;
  height: 50px;
}
.login-button:hover {
  background-color: rgb(52, 51, 51);
  filter: brightness(1.5);
}

@media screen and (max-width: 1000px) {
  .image-login-container {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .image-login-container {
    display: none;
  }
  .login-content-container {
    box-shadow: none;
    margin-top: 0;
  }
  .login-container {
    padding: 5px;
  }
  .form-login-container {
    gap: 10px;
  }
}

@media screen and (max-height: 980px) {
  .login-content-container {
    height: 800px;
  }
}

@media screen and (max-height: 820px) {
  .login-content-container {
    height: 650px;
  }
}
