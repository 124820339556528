// --------------------------------------------------------------------------------------------------
// layout for app
// --------------------------------------------------------------------------------------------------
html {
	height: 100%;
	font-size: $font-size-base !important;
}

body {
	margin: 0;
	height: 100%;
	background-color: #fff;
	color: $font-color;
	font-family: $font-face-primary;
	font-size: $font-size-base !important;

	&.overflow-hidden {
		overflow-y: hidden;

		.outer-container {
			overflow-x: hidden;
		}
	}
}
// .overlay {
// 	width: 100%;
// 	top: 69px;
// 	height: 100%;
// 	z-index: 100;
// 	pointer-events: none;
// 	background-color: rgba(195, 195, 195, 0.75);
// 	position:absolute;
// }


.outer-container {
	display: flex;
	height: 100%;
}

.inner-container {
	min-width: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	+ div {
		display: none;
	}
}

.help-panel + div {
	display: none;
}

@media (min-width: 1200px) {
	.inner-container {
		min-width: 0;
		width: 100%;
	}
}

.page-title-container {
	display: flex;
	height: 70px;
	align-items: stretch;
	background-color: #fff;
	@include border(1px, solid, $grey-box-border, bottom);
}

.page-menu-title {
	min-width: 230px;
	@include border(1px, solid, $grey-box-border, right);
	@include padding(10px, null, 10px, 20px);
	@include font-size(18px);
	font-weight: 400;
	display: flex;
	align-items: center;
    background-color: #fff;

}

.page-title {
	width: 100%;
	display: flex;
	position: relative;
	flex-direction: row;
	align-items: center;
	margin-left: 10px;
    background-color: #fff;

	@include padding(10px, null, 10px, null);

	@include screen-size(medium) {
		margin-left: 0px;
        padding-left:24px;
	}

	.title {
		@include margin(null, null, 0px, null);
		@include font-size(18px);
		font-weight: 400;
	}

	.toggle-help {
		@include margin(null, 10px, null, auto);

		@include screen-size(medium) {
			@include margin(null, 25px, null, auto);
		}

	}
}


.flex-full-height {
	height: 100%;
	display: flex;
}

.page-content-container {
	height: 100%;
	overflow: hidden;
	background-color: #fff;
}

.page-content {
	width:100%;
	@include padding(25px, 25px, 25px, 25px);
	// @include margin(null, $container-gutter-small, 25px, $container-gutter-small);
	overflow-y: auto;
	position: relative;
	// background-color: #fff;
	// @include border(1px, solid, $grey-box-border);

	// @include screen-size(large) {
	// 	@include margin(10px, $container-gutter-large, 25px, $container-gutter-large);
	// }
}

.card {
	position: relative;
	@include padding(null, null, 20px, null);

	.title {
		display: flex;
		align-items: center;
		@include font-size(18px);
		font-family: $font-face-bold;
		@include margin(null, null, $gutter-small, null);
	}

	&.with-border {
		margin: 0 0 15px 0 !important;
		padding: $card-gutter-small 15px $card-gutter-small 15px !important;
		@include border(1px, solid, $grey-box-border);
		background-color: #fff;

		@include screen-size(large) {
			margin: 0 0 $gutter-medium 0 !important;
			padding: 20px $card-gutter-large !important;
		}
	}
}
