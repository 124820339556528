@import "./scss/abstracts/variables";
@import "./scss/abstracts/functions";
@import "./scss/abstracts/mixins";

@import "./scss/layout/layout";
@import "./scss/base/typography";

@import "./scss/components/buttons";
@import "./scss/components/forms";
@import "./scss/components/page-intro";

// OLD CSS DELETE ONCE REFACTORED

.textbox-homepage {
  width: 100%;
  max-width: 500px;
  padding: 9px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  resize: none;
  color: black;
  border: 0.5px solid darkgray;
}
.primary-btn {
  background-color: $brand-color-4;
  color: rgb(209, 206, 206);
  padding: 10px;
  border-radius: 10px;
  transition: filter 300ms;
  min-width: 200px;
  max-width: 45%;
}

.table-btn {
    background-color: rgb(26, 28, 30);
    color: rgb(209, 206, 206);
    padding: 4px;
    border-radius: 4px;
    height: 32px;
    width: 100px;
    }

.warning-btn {
  background-color: rgb(255, 22, 53);
  color: rgb(209, 206, 206);
  padding: 10px;
  border-radius: 10px;
  transition: filter 300ms;
  min-width: 20%;
  max-width: 45%;
}
.warning-btn:hover {
  filter: brightness(1.5);
}
.primary-btn:hover {
  filter: brightness(1.5);
}
.primary-btn:disabled {
  background-color: rgba(26, 28, 30, 0.1);
  filter: brightness(1);
}
.warning-btn {
  background-color: "#DC3344";
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.action-button.inactive {
  color: grey;
  cursor: default;
}
.action-button.inactive:hover {
  background-color: transparent;
}
.action-button {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-size: 15;
  cursor: pointer;
  transition: background-color 300ms;
  color: #3546a2;
  font-weight: bold;
}
.action-button:hover {
  background-color: rgba(179, 185, 190, 0.3);
}
.overlay {
  width: calc(100%);
  top: 69px;
  height: calc(100% - 69px);
  z-index: 100;
  pointer-events: none;
  background-color: rgba(195, 195, 195, 0.75);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputGroup {
  // font-family: "Segoe UI", sans-serif;
  margin: 1em 1em 1em 1em;
  max-width: 340px;
  width: 100%;
  max-height: 100px;
  position: relative;
} 

.inputGroup input {
  font-size: 100%;
  padding: 0.6em 0.6em 0.6em 0.6em;
  outline: none;
  border: 2px solid rgb(200, 200, 200);
  background-color: white;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.inputGroup label {
  font-size: 100%;
  position: absolute;
  left: 20px;
  top: 11px;
  /* padding: 0.8em;
    margin-left: 0.5em; */
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(100, 100, 100);
}

.inputGroup :is(input:focus, input:valid) ~ label {
  transform: translateY(-50%) scale(0.9);
  margin: 0em;
  left: 20px;
  /* margin-left: 1.3em; */
  /* padding: 0.4em; */
  top: 0;
  background-color: white;
}

.inputGroup :is(input:focus, input:valid) {
  border-color: rgb(0, 193, 213);
}

a {
  color: $brand-color-2;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $brand-color-2;
  }
}

.hyper-link {
  color: $brand-color-2;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #57b197;
  }
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

