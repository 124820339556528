.profile-page-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

}
.profile-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    flex : 1;
    height: 100vh;
    gap:20px

}
.profile-edit-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    width : 100%;
    flex : 2;
    height: 100vh;
    gap:20px

}
.icon-profile-page img {
    border-radius: 50%;
	width: 150px;
	height: 150px;
}
.about-container{
    display:flex;
    padding : 25px;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    gap:20px;
    width : 100%;
    border-radius: 10px;
    box-shadow : 0px 3px 15px rgb(0 0 0 / 20%)

}
.about-line{
    color:grey;
}
.radio-btn-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 5px;

}

.radio-btn{
    display: flex;
    flex-direction:row;
    gap: 10px;
    justify-content:center;
    border: 2px solid lightgrey;
    padding : 10px;
    border-radius: 5px;
    width : 120px;
    cursor: pointer;
}
.radio-btn:hover {
    background-color: rgb(39,43,48);
    color: white
}
.form-container {
    display: flex;
    flex-direction: column;
    box-shadow : 0px 3px 15px rgb(0 0 0 / 20%);
    padding : 10px 45px 10px 45px;
    align-items: center;
    gap: 20px;
    border-radius: 10px;


}
.radio-btn.active {
    background-color: black;
    color :white
}
.option:hover input ~ .radio-btn {
    background-color: #ccc;
  }