@import 'scss/abstracts/variables';
@import 'scss/abstracts/functions';
@import 'scss/abstracts/mixins';

.back-button {
    font-size: 35px !important;
    font-variation-settings:  'FILL' 0,   'wght' 200,   'GRAD' -25,   'opsz' 40;
    color: $brand-color-2;
    @include margin(null, 5px, null, null);
    cursor: pointer;

    &:hover {
        color: $brand-color-1;
    }

}