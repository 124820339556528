.input-container {
	max-width: 100%;

	@include screen-size(medium) {
		max-width: 400px;
	}
}

.input-group {
    position: relative;
    width: 100%;
    @include margin(null, null, $gutter-small, null);

    &.is-invalid {
        background: transparent;

        label {
            color: $color-error;
        }

        .textbox, .select-menu, .textarea {
            @include border(1px, solid, $color-error);
            background-color: $color-error-background;
        }
    }
}

label {
	display: block;
	@include margin(null, null, 5px, null);

	&.bold {
		font-family: $font-face-semi-bold;
	}

    .icon {
        color: $brand-color-2;
    }
}

.textbox {
	width: 100%;
	@include border(1px, solid, $input-border-color);
	@include padding(6px, 12px, 6px, 12px);
	color: $input-text-color;
	height: $input-height;

	&:focus {
		@include border(2px, solid, $brand-color-2);
	}

	&:focus-visible {
		outline: none;
	}

	&:disabled {
		background-color: $input-disabled;
	}

	&:focus::placeholder {
		color: transparent;
	}
}

.textarea {
	@include border(1px, solid, $input-border-color);
	@include padding(6px, 12px, 6px, 12px);
	color: $input-text-color;
	width: 100%;
	resize: vertical;

	&:focus {
		border-color: $brand-color-1;
	}

	&:focus-visible {
		outline: none;
	}

	&:disabled {
		background-color: $input-disabled;
	}
}


.select-menu {
	@include border(1px, solid, $input-border-color);
	@include padding(null, 30px, null, 10px);
	color: $input-text-color;
	height: $input-height;
	line-height: $input-height;
	width: 100%;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{url-friendly-colour($input-text-color)}' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 8px center;
	background-size: 16px 16px;
	background-color: #fff;
	cursor: pointer;

	&:focus-visible {
		outline: none;
	}

	&:focus {
		border-color: $brand-color-1;
	}

	&:disabled {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23aaa' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
		background-color: $input-disabled;
	}
}