// -----------------------------------------------------------------------------------------------
// fonts
// -----------------------------------------------------------------------------------------------
// @font-face {
// 	font-family: 'Light';
// 	src: url("/assets/custom-fonts/URWGeometric-Light.woff");
// }

// @font-face {
// 	font-family: 'Thin';
// 	src: url("/assets/custom-fonts/URWGeometric-Thin.woff");
// }

// @font-face {
// 	font-family: 'Regular';
// 	src: url("/assets/custom-fonts/URWGeometric-Regular.woff");
// }

// @font-face {
// 	font-family: 'Medium';
// 	src: url("/assets/custom-fonts/URWGeometric-Medium.woff");
// }

// @font-face {
// 	font-family: 'SemiBold';
// 	src: url("/assets/custom-fonts/URWGeometric-SemiBold.woff");
// }

// @font-face {
// 	font-family: 'Bold';
// 	src: url("/assets/custom-fonts/URWGeometric-Bold.woff");
// }

// @font-face {
// 	font-family: 'ExtraBold';
// 	src: url("/assets/custom-fonts/URWGeometric-ExtraBold.woff");
// }

// -----------------------------------------------------------------------------------------------
// font faces and sizes
// -----------------------------------------------------------------------------------------------
$font-size-base: 15px;
$font-size-medium: 12px;
$font-size-small: 10px;
$font-face-thin: 'Thin', arial, sans-serif;
$font-face-light: 'Light',  arial, sans-serif;
$font-face-regular: 'Roboto';
$font-face-medium: 'Medium',  arial, sans-serif;
$font-face-semi-bold: 'SemiBold',  arial, sans-serif;
$font-face-bold: 'Bold',  arial, sans-serif;
$font-face-extra-bold: 'ExtraBold',  arial, sans-serif;
$font-face-primary: $font-face-regular;

$font-color: #333;

// -----------------------------------------------------------------------------------------------
// screen breakpoints
// -----------------------------------------------------------------------------------------------
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-x-large: 1200px;
$breakpoint-xx-large: 1320px;
$breakpoint-xxx-large: 1850px;

// -----------------------------------------------------------------------------------------------
// pages column gutters
// -----------------------------------------------------------------------------------------------
$gutter-small: 20px;
$gutter-medium: 30px;
$gutter-large: 50px;
$container-gutter-small: 10px;
$container-gutter-large: 25px;
$card-gutter-small: 15px;
$card-gutter-large: 20px;

// -----------------------------------------------------------------------------------------------
// branding colours
// -----------------------------------------------------------------------------------------------
$brand-color-1: #1A1C1E; // evata black
$brand-color-2: #00c1d5; 
$brand-color-3: #f8f8fa;
$brand-color-4: #1A1C1E;

// -----------------------------------------------------------------------------------------------
// link styling
// -----------------------------------------------------------------------------------------------
$link-color: $brand-color-2;
$link-hover-color: $brand-color-1;
$link-focus-color: $brand-color-2;

// -----------------------------------------------------------------------------------------------
// input styling
// -----------------------------------------------------------------------------------------------
$input-border-color: #999999;
$input-text-color: $font-color;
$input-error-border-color: #d0020b;
$input-error-background: #fbecec;
$input-disabled: #e3e3e3;
$input-height: 35px;
$input-max-width: 395px;

$checkbox-radio-focus-color:$brand-color-1;
$checkbox-radio-hover-color: white;
$checkbox-radio-focus-width: 4px;

// -----------------------------------------------------------------------------------------------
// errors and warning colours
// -----------------------------------------------------------------------------------------------
$color-error: #d0020b;
$color-error-hover:  #aa2a10;
$color-error-background: #f8d7da;
$color-error: #d0020b;
$color-warning: #856404;
$color-warning-background: #fff3cd;
$color-success: #00703c;
$color-success-background: #d1e7dd;
$color-info: #306391;
$color-info-background: #dcedfc;

// -----------------------------------------------------------------------------------------------
// buttons colours
// -----------------------------------------------------------------------------------------------
$button-primary-background: $brand-color-4;
$button-primary-color: #fff;
$button-secondary-background: $brand-color-1;
$button-secondary-color: #fff;
$button-tertiary-background: #fff;
$button-tertiary-color: $brand-color-1;

// -----------------------------------------------------------------------------------------------
// grey box
// -----------------------------------------------------------------------------------------------
$grey-box-background: #f5f5f5;
$grey-box-active-background: #dedddd;
$grey-box-border: #ced4da;

// -----------------------------------------------------------------------------------------------
// table
// -----------------------------------------------------------------------------------------------
$table-row-hover: #E4F8FA;

// -----------------------------------------------------------------------------------------------
// borders
// -----------------------------------------------------------------------------------------------
$border-radius: 0;

// -----------------------------------------------------------------------------------------------
// spinners
// -----------------------------------------------------------------------------------------------
$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: .25em;
$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: .2em;

// -----------------------------------------------------------------------------------------------
// page section
// -----------------------------------------------------------------------------------------------
$page-section-background: #f8f8fa;