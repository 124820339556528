.configure-tab-screen-box {
  width: 550px;
  display: flex;
  gap: 20px;
  /* justify-content: space-between; */
  flex-direction: column;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 10px;
}
.configure-tab-screen-box input {
  width: 70%;
  text-align: center;
  margin: 0;
  display: block;
}
