.error-message h1{
	font-size: 32px;
}
.error-message p{
	color: darkgray;
}

.error-message{
	min-height: calc(100vh - 100px);
	padding-top: calc(35vh - 25px);
	padding-bottom: 35vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.error-icon{
	width: 50px;
	height: 50px;
}

.landing.error-message{
	padding-top: 35vh;
	width: 100%;
	margin-top: -10vh;
}