.user-survey-container{
    max-width: 1600px;
    flex-direction: column;
    width : 100%;
    display: flex;
    padding: 32px;
    

    
}
.user-survey-title{
    font-weight: bolder;
    font-size: 30px;
    text-align: center;
}

input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }